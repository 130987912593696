(function ($) {

    // OPEN MENU
    $('.header__burger').on('click', function () {
        $('.header__burger').addClass('header__burger__open');
        $('.header__navigation').addClass('header__navigation__open');
    });

    // CLOSE MENU
    $('.header__close').on('click', function () {
        $('.header__burger').removeClass('header__burger__open');
        $('.header__navigation').removeClass('header__navigation__open');
    });

    $(document).mouseup(function (e) {
        var container = $(".header__wrapp");
        if (container.has(e.target).length === 0){
            $('.header__burger').removeClass('header__burger__open');
            $('.header__navigation').removeClass('header__navigation__open');
        }
    });

    //SMOOTH SCROLL
    $(".header__menu").on("click",".nav-link", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),

        top = $(id).offset().top;
        $('body,html').animate({
            scrollTop: top
        }, 1500);
    });

    // SLIDERS
    $('.js-assist').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    arrow: false,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    arrow: false,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrow: false,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    $('#back-top a').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    if (window.matchMedia("(max-width: 767px)").matches) {
        /* the viewport is less than 768 pixels wide */
        $(".guarantees-items").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" >' +
                '<svg width="7" height="12" viewBox="0 0 7 12"><defs><path id="6513a" d="M41.25 3200.6l5.28 5.15c.34.33.88.33 1.22 0a.83.83 0 0 0 0-1.18l-4.67-4.57 4.67-4.57a.83.83 0 0 0 0-1.18.87.87 0 0 0-1.22 0l-5.28 5.16a.83.83 0 0 0 0 1.18z"/></defs><use  transform="translate(-41 -3194)" xlink:href="#6513a"/></svg>' +
                '</button>',
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;">' +
                '<svg width="7" height="12" viewBox="0 0 7 12"><defs><path id="dbxia" d="M334.75 3199.6l-5.28 5.15a.87.87 0 0 1-1.22 0 .83.83 0 0 1 0-1.18l4.67-4.57-4.67-4.57a.83.83 0 0 1 0-1.18.87.87 0 0 1 1.22 0l5.28 5.16a.83.83 0 0 1 0 1.18z"/></defs><use  transform="translate(-328 -3193)" xlink:href="#dbxia"/></svg>' +
                '</button>'
        })
    }

    $(".tab-item_js").click(function () {
        $(".tab-item_js").removeClass("active");
        $(this).addClass("active");
        $(".tab-content_js").hide()
        $(".tab-content_js").eq($(this).index()).fadeIn()
    })

    // ACCORDION
    $('.faq__items').click(function(e) {
        e.preventDefault();

        var $this = $(this);

        $('.faq__inner-js').slideUp();
        $('.plus').removeClass('opened');
        $('.toggle-wrapp').removeClass('toggle-wrapp-open');

        if(!$('.faq__inner-js').eq($(this).index()).is(':visible')){
            $('.faq__inner-js').eq($(this).index()).slideDown();
            $this.find('.plus').addClass('opened');
            $this.find('.toggle-wrapp').addClass('toggle-wrapp-open');
        }

    });

    var header = $(".ceiu_header");
    $(window).scroll(function () {
        $(window).scrollTop() > 40 ? header.addClass("header_smush") : header.removeClass("header_smush")
    }),

        $("#nav-icon1").click(function () {
            $(this).toggleClass("open"),
                $(".header__nav").slideToggle()
        }), $("#nav-icon2").click(function () {
        $(this).toggleClass("open"),
            $(".header__nav_other .nav__item").toggleClass("show-item")
    }),
        $(".experts__item").hover(function () {
            $(this).children().children(".experts__desc").toggleClass("experts__desc_hide")
        })

    function btnClickMia() {
        $('.mia-chat').css('display', 'block');
        var openEvent = new Event('mia:open');
        window.document.dispatchEvent(openEvent);
    }

    function btnClickCallback() {
        $('.cr-cb-button-block').click();
    }

    $(".header__number").click(function (e) {
        e.preventDefault();
        btnClickCallback();
    });

    $(".js-mia").click(function (e) {
        e.preventDefault();
        btnClickMia();
    });

    $(".js-anchor").click(function (s) {
        s.preventDefault();
        var r = $($(this).attr("href"));
        if (r.length) {
            var o = r.offset().top;
            $("body, html").animate({scrollTop: o + "px"}, 800)
        }
        setTimeout(function () {
            $('.header__burger').removeClass('header__burger__open');
            $('.header__navigation').removeClass('header__navigation__open');
        }, 1000);
    });

    // order form preloader
    window.addEventListener("DOMContentLoaded", function () {
        var customOrder = document.querySelector("[data-crm-widget=form]");
        if (customOrder) {
            customOrder.addEventListener("ready", function () {
                $('.order-preloader').remove();
            });
        }
    });
})(jQuery);

